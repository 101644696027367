* {
    margin:0;
    padding:0;
    box-sizing:border-box;

}
body{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    background: #f1f5f8;
    color:rgb(167, 127, 127);
}
.booklist{
    width: 10vw;
    max-width:1170px;
    margin:1rem;
    display:grid;
    gap:2rem;
}
.book {
    background: rgb(255, 255, 255);
    border-radius: 10rem;
    padding: 1rem 2rem;

}

.book h2 {
    margin-top: 0.5rem;

}
@media screen and (min-width: 768px){
    .booklist {
        grid-template-columns: repeat(3,1fr);
    }
}